import React, { useState } from "react";
import ReactDOM from "react-dom";

const NewsletterForm = ({ chunksCount, usersCount, newsletterUrl }) => {
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [sendChunks, setSendChunks] = useState([]);
  const range = (start, end) => {
    return Array.from({ length: end - start }, (_, index) => index + start);
  };
  const chunks = range(0, chunksCount);

  const sendChunk = (chunk) => {
    fetch(newsletterUrl, {
      method: "POST",
      body: JSON.stringify({ chunk, subject, text }),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(() => {
        setSendChunks([...sendChunks, chunk]);
      });
  };

  return (
    <React.Fragment>
      <h1 className="text-4xl text-center mb-10">
        Newsletter ({usersCount} users)
      </h1>
      <div>
        <label className="block text-xs font-semibold text-gray-600 uppercase">
          Subject
        </label>
        <input
          type="text"
          value={subject}
          className="block w-full py-3 px-1 mt-2 text-gray-800 appearance-none border-2 border-gray-200 focus:outline-none focus:border-gray-300"
          onChange={(e) => setSubject(e.target.value)}
        />
        <label className="block text-xs font-semibold text-gray-600 uppercase mt-2">
          Text
        </label>
        <textarea
          className="block w-full py-3 px-1 mt-2 text-gray-800 appearance-none border-2 border-gray-200 focus:outline-none focus:border-gray-300"
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <button
          className="w-full py-3 mt-7 bg-el-teal rounded-sm font-medium text-white uppercase focus:outline-none hover:bg-el-teal-dark hover:shadow-none cursor-pointer"
          onClick={() => sendChunk("test")}
        >
          Send test email
        </button>
        {chunks.map((chunk) => (
          <div key={chunk}>
            <button
              className="w-full py-3 mt-7 bg-red-600 rounded-sm font-medium text-white uppercase focus:outline-none enabled:hover:bg-red-800 enabled:hover:shadow-none cursor-pointer block disabled:opacity-75 disabled:cursor-not-allowed"
              disabled={sendChunks.includes(chunk)}
              onClick={() => sendChunk(chunk)}
            >
              Send email to chunk {chunk}
            </button>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const $newsletterForm = document.getElementById("newsletter-form");
  if (!$newsletterForm) {
    return;
  }

  ReactDOM.render(
    <NewsletterForm
      chunksCount={window.data.chunks_count}
      usersCount={window.data.users_count}
      newsletterUrl={window.data.newsletter_url}
    />,
    $newsletterForm
  );
});
